import { MatchTime } from 'api/src/entities/opta/match/MatchTime';

import { MatchPeriod } from '@common/clients/api';

export const getMatchTime = (
    minuteParam: number,
    period: MatchPeriod | undefined,
    startOfCurrentPeriod?: Date,
): MatchTime => {
    const now = new Date();

    let minutes = startOfCurrentPeriod
        ? Math.floor((now.valueOf() - startOfCurrentPeriod.valueOf()) / 1000 / 60)
        : minuteParam;

    let overtime: number = 0;
    let maxMinutes: number = 0;

    switch (period) {
        case MatchPeriod.FIRST_HALF:
            maxMinutes = 45;
            break;

        case MatchPeriod.SECOND_HALF:
            if (startOfCurrentPeriod) minutes += 45;
            maxMinutes = 90;
            break;

        case MatchPeriod.EXTRA_TIME_FIRST_HALF:
            if (startOfCurrentPeriod) minutes += 90;
            maxMinutes = 105;
            break;

        case MatchPeriod.EXTRA_TIME_SECOND_HALF:
            if (startOfCurrentPeriod) minutes += 105;
            maxMinutes = 120;
            break;
    }

    if (minutes > maxMinutes) {
        overtime = minutes - maxMinutes;
        minutes = maxMinutes;
    }

    return { minutes, overtime };
};
